<template>
    <div>
        <header-portrate />
        <exports-print ref="exportbar" style="display:none;"  />
        
        <div>
            <tabsComp :tabs="tabs" style="display:none;" />
            <v-row class="mainrow m-2" :style="`direction:`+lang.dir+`;margin-top:20px !important;`">
                <v-col
                cols="12"
                md="10"
                sm="12">
                <form autocomplete="off">
                    <v-row>
                        <v-col cols="12" md="2" sm="12">
                            <label>{{lang.start_date}}</label>
                            <b-input-group>
                            <b-form-select class="inborder text-center" @change="doStart()" style="width:33%;background:#efefef;" v-model="sd.day" :options="daysList"/>
                            <b-form-select class="inborder text-center" @change="doStart()" style="width:33%;background:#efefef;border-right:none !important;" v-model="sd.month" :options="monthsList" />
                            <b-form-select class="inborder text-center" @change="doStart()" style="width:33%;background:#efefef;" v-model="sd.year" :options="yearsList" />
                            </b-input-group>
                        </v-col>
                        <v-col cols="12" md="2" sm="12">
                            <label>{{lang.end_date}}</label>
                            <b-input-group>
                            <b-form-select class="inborder text-center" @change="doStart()" style="width:33%;background:#efefef;" v-model="ed.day" :options="daysList" />
                            <b-form-select class="inborder text-center" @change="doStart()" style="width:33%;background:#efefef;border-right:none !important;" v-model="ed.month" :options="monthsList" />
                            <b-form-select class="inborder text-center" @change="doStart()" style="width:33%;background:#efefef;" v-model="ed.year" :options="yearsList" />
                            </b-input-group>
                        </v-col>

                        <v-col cols="12" md="2" sm="12">
                            <label></label>
                            <b-input-group>
                            <b-button variant="light" style="padding-top:2px !important;padding-bottom:2px !important;background:blue;color:#fff;width:75px;"  @click="getDashboard()">{{lang.search}}</b-button>
                            </b-input-group>
                        </v-col>
                    </v-row>
                </form>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <div class="dashBoard">
                        <h5 class="backBlack" style="width:calc(12% * 8);padding:3px;text-align:center;font-weight:bold">الحسابات</h5>
                        <div v-for="(item,index) in tablerows.accounting" :key="index" style="margin-bottom:10px;border:1px solid #000;width: 11.5%;margin-inline:5px;padding:0">
                            <div class="backBlack" style="border:1px solid #bbb;padding:5px;text-align:center;font-size:.8rem;">{{ item[`name${lang.langname}`] }}</div>
                            <div class="text-center" style="padding:5px;margin-bottom:2px;border-bottom:1px solid #bbb;display:flex;justify-content:space-between;"> <span style="margin-inline-start:10px;font-size:.8rem;">{{ item[`title_${lang.langname}`] }}:</span>  <span style="margin-inline-end:10px;">{{ $RoundNums(item.total,2) }}</span></div>
                            <div class="blackBack" style="border:1px solid #bbb;padding:5px;text-align:center;cursor:pointer" @click="showTable(item)">{{ lang.view }}</div>
                        </div>
                    </div>
                    <div class="dashBoard">
                        <h5 class="backBlack" style="width:calc(12% * 8);padding:3px;text-align:center;font-weight:bold">المبيعات</h5 >
                        <div v-for="(item,index) in tablerows.sales" :key="index" style="margin-bottom:10px;border:1px solid #000;width: 11.5%;margin-inline:5px;padding:0">
                            <div class="backBlack" style="border:1px solid #bbb;padding:5px;text-align:center;font-size:.8rem;">{{ item[`name${lang.langname}`] }}</div>
                            <div class="text-center" style="padding:5px;margin-bottom:2px;border-bottom:1px solid #bbb;display:flex;justify-content:space-between;"> <span style="margin-inline-start:10px;font-size:.8rem;">{{ item[`title_${lang.langname}`] }}:</span>  <span style="margin-inline-end:10px;">{{ $RoundNums(item.total,2) }}</span></div>
                            <div class="blackBack" style="border:1px solid #bbb;padding:5px;text-align:center;cursor:pointer" @click="showTable(item)">{{ lang.view }}</div>
                        </div>
                    </div>
                    <div class="dashBoard">
                        <h5 class="backBlack" style="width:calc(12% * 8);padding:3px;text-align:center;font-weight:bold">الفواتير</h5 >
                        <div v-for="(item,index) in tablerows.invoices" :key="index" style="margin-bottom:10px;border:1px solid #000;width: 11.5%;margin-inline:5px;padding:0">
                            <div class="backBlack" style="border:1px solid #bbb;padding:5px;text-align:center;font-size:.8rem;">{{ item[`name${lang.langname}`] }}</div>
                            <div class="text-center" style="padding:5px;margin-bottom:2px;border-bottom:1px solid #bbb;display:flex;justify-content:space-between;"> <span style="margin-inline-start:10px;font-size:.8rem;">{{ item[`title_${lang.langname}`] }}:</span>  <span style="margin-inline-end:10px;">{{ $RoundNums(item.total,2) }}</span></div>
                            <div class="blackBack" style="border:1px solid #bbb;padding:5px;text-align:center;cursor:pointer" @click="showTable(item)">{{ lang.view }}</div>
                        </div>
                    </div>
                </v-col>
            </v-row>
        </div>
        <Footer />
        <fixed-assets ref="FixedAssets" />
        <statement ref="statement" />
        <getApproved ref="getApproved" />
        <CustomerList ref="CustomerList" />
        <ErpQuotationsList ref="ErpQuotationsList" />
        
    </div>
</template>

<script>
import HeaderPortrate from '@/components/Header-Portrate.vue';
import Footer from '@/components/Footer.vue';
import tabsComp from '@/components/tabsComp.vue'
import ExportsPrint from '@/components/exports-print.vue';
import axios from 'axios'
import FixedAssets from '@/components/dashboard/fixed-assets.vue';
import getApproved from '@/components/dashboard/getApproved.vue';
import Statement from '@/components/dashboard/statement.vue';
import CustomerList from '@/components/dashboard/CustomerList.vue';
import ErpQuotationsList from '@/components/dashboard/ErpQuotationsList.vue';
export default{
    components: {HeaderPortrate,Footer,tabsComp,ExportsPrint, FixedAssets, Statement,getApproved,CustomerList,ErpQuotationsList},
    data(){
        return {
            showtable: false,
            showFix: false,
            sd: {
                day: 1,
                month: 1,
                year: (new Date()).getFullYear()
            },
            ed: {
                day: (new Date()).getDate(),
                month: (new Date()).getMonth() + 1,
                year: (new Date()).getFullYear()
            },
            tablerows: {
                accounting: [],
                sales: [],
                invoices: [],
            },
            search: '',
        }
    },
    computed:{
        lang: function(){
          if(this.$cookies.get(this.$store.state.LangCooki) != null){
              if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
              return this.$store.state.lang.en;
              }else{
              return this.$store.state.lang.ar;
              }
          }
          else if(this.$store.state.deflang == 'en'){
              return this.$store.state.lang.en;
          }else{
              return this.$store.state.lang.ar;
          }
      },
      showAccounting: function(){
        let t = false;
        const _ecar = localStorage.getItem("_ECar_");
        const _ecar_ = JSON.parse(_ecar);
        if(_ecar_.activemenu == 'erpsam') t = true;
        return t;
      },
      shoContract: function(){
        let t = false;
        const _ecar = localStorage.getItem("_ECar_");
        const _ecar_ = JSON.parse(_ecar);
        if(_ecar_.car_contract) t = true;
        return t;
      },
      sdate: function() {
        return (new Date(this.sd.year,this.sd.month-1, this.sd.day+1)).toISOString().substring(0,10);
      },
      edate: function() {
        return (new Date(this.ed.year,this.ed.month-1, this.ed.day+1)).toISOString().substring(0,10);
      },
      yearsList: function(){
        const start_year = this.$store.state.licenseType.startYear;
        let t = [{text: this.lang.year, value: -1}];
        for(let i=0;i<100;i++){
          if(parseInt(start_year) + i <= (new Date()).getFullYear()){
            t.push({text: start_year + i, value: start_year+i})
          }
          
        }
        return t
      },
      daysList: function(){
        return [
          {text: this.lang.day, value: -1},
          {text: 1, value: 1},
          {text: 2, value: 2},
          {text: 3, value: 3},
          {text: 4, value: 4},
          {text: 5, value: 5},
          {text: 6, value: 6},
          {text: 7, value: 7},
          {text: 8, value: 8},
          {text: 9, value: 9},
          {text: 10, value: 10},
          {text: 11, value: 11},
          {text: 12, value: 12},
          {text: 13, value: 13},
          {text: 14, value: 14},
          {text: 15, value: 15},
          {text: 16, value: 16},
          {text: 17, value: 17},
          {text: 18, value: 18},
          {text: 19, value: 19},
          {text: 20, value: 20},
          {text: 21, value: 21},
          {text: 22, value: 22},
          {text: 23, value: 23},
          {text: 24, value: 24},
          {text: 25, value: 25},
          {text: 26, value: 26},
          {text: 27, value: 27},
          {text: 28, value: 28},
          {text: 29, value: 29},
          {text: 30, value: 30},
          {text: 31, value: 31},
        ]
      },
      monthsList: function(){
        return [
          {text: this.lang.chose_month, value: -1},
          {text: 1, value: 1},
          {text: 2, value: 2},
          {text: 3, value: 3},
          {text: 4, value: 4},
          {text: 5, value: 5},
          {text: 6, value: 6},
          {text: 7, value: 7},
          {text: 8, value: 8},
          {text: 9, value: 9},
          {text: 10, value: 10},
          {text: 11, value: 11},
          {text: 12, value: 12},
        ]
      },
      items:function() {
          return {
            text: this.lang.invoices,
            disabled: true,
            href: '/invoicelist/',
          }
      },
      tabs: function(){
        
        const menu = this.$store.getters['state'].menu;
            const license = this.$store.getters['state'].licenseType.activemenu;
            const alicense = this.$store.getters['state'].licenseType
            let currentMenu = menu[license];
            if(!license){
              return false;
            }
            let cMenu = {main: {},sub:[]};
            Object.values(currentMenu).forEach(value =>{
            const cM = localStorage.getItem('currentMenu');
            if(value.main.index == cM){
                cMenu = value.sub;
            }else if(cM == null && value.main.index == 1){
                cMenu = value.sub;
            }
            });
           
            let t = []
            Object.values(cMenu).forEach(value =>{
            
                if(this.$route.path == value.href){
                    value.class = "mytab";
                }else{
                    value.class = "mytab4";
                }
                if(this.lang.langname == 'ar'){
                    value.name = value.arname
                }else{
                    value.name = value.enname
                }
                // // console.log("value",license);
                for(let i =0; i< value.groupid.length;i++){
                    if(value.groupid[i] == this.$store.getters['state'].group_id){
                      if(alicense[value.perms[0]] || value.perms[0] == 'any')
                          t.push(value)
                    }
                }
            })
        
            return t;
      }
    },
    methods:{
        showTable(item){
            console.log("items" , item);
            if(item.type == 'fixed-assets'){
                this.$refs.FixedAssets.getReport();
            }
            if(item.type == 'aproval'){
                this.$refs.getApproved.getReport();
            }
            else if (item.type == 'accounting')
            {
                this.$refs.statement.account_name = item[`name${this.lang.langname}`]
                this.$refs.statement.search = {
                    sdate: this.sdate, edate: this.edate
                }
                this.$refs.statement.classid = item.class
                this.$refs.statement.getReport();
            }
            else if (item.type == 'customer_source')
            {
                // this.$refs.CustomerList.options = item
                this.$refs.CustomerList.getReport(item);
            }
            else if (item.type == 'quotations_count')
            {
                // this.$refs.CustomerList.options = item
                this.$refs.ErpQuotationsList.getReport(item);
            }
        },
        getDashboard(){
            let cook = this.$cookies.get(this.$store.state.COOKIEPhase);
            let post = new FormData();
            post.append('type','getDashboard');
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append('lang',this.$cookies.get(this.$store.state.LangCooki))
            post.append('auth',cook);
            post.append('data[start]',this.sdate);
            post.append('data[end]',this.edate)
            axios.post(
                this.$store.state.SAMCOTEC.r_path,post
            ).then((response) => {
                if(response && response.data && response.data.results){
                    this.tablerows.accounting = response.data.results.data.accounting;
                    this.tablerows.sales = response.data.results.data.sales;
                    this.tablerows.invoices = response.data.results.data.invoices;
                }
            })
            // console.log(response.data)
            
        }
    },
    created(){
        this.getDashboard();
    }
}

</script>

<style scoped>
.cusModal{
    position: fixed;
    background:#0000001a;
    top:0;
    left:0;
    bottom:0;
    right:0;
    z-index: 20000000;
    display:flex;
    justify-content: center;
    justify-items: center;
    align-items: center;;
}
.cusModalCont{
    position:relative;
    width:70%;
    height: 70vh;
    border:1px solid #bbb;
    background:#fff;
    box-shadow: 0px 1px 1px 1px #0000001a;
    border-radius: 5px;
}
.cusModalClose{
    position: absolute;
    top:2px;
    left:10px;
    background:red;
    color:#fff;
    border:1px solid #555;
    border:#ccc;
    border-radius: 2px;
    padding:4px 10px;
    cursor: pointer;
    box-shadow: 0px 1px 1px 1px #0000001a;
}
.cusModalCloseL:hover{
    background: darkred;
}
.cusModalTitle{
    padding:5px;
    text-align:center;
    border-color: #bbb !important;
    border-bottom-color: #000 !important;
}
.cusModalBody{
    overflow: auto;
    height:calc(70vh - 50px);
    max-height:calc(70vh - 50px);
    padding:5px;
}
.dashBoard{
    margin-bottom:20px;
    display:flex;
    flex-direction:row;
    flex-wrap:wrap;
    justify-content:center;
    justify-items:center;
    align-self:center;
}
.cusModal{
    position: fixed;
    background:#0000001a;
    top:0;
    left:0;
    bottom:0;
    right:0;
    z-index: 20000000;
    display:flex;
    justify-content: center;
    justify-items: center;
    align-items: center;;
}
.cusModalCont{
    position:relative;
    width:70%;
    height: 70vh;
    border:1px solid #bbb;
    background:#fff;
    box-shadow: 0px 1px 1px 1px #0000001a;
    border-radius: 5px;
}
.cusModalClose{
    position: absolute;
    top:2px;
    left:10px;
    background:red;
    color:#fff;
    border:1px solid #555;
    border:#ccc;
    border-radius: 2px;
    padding:4px 10px;
    cursor: pointer;
    box-shadow: 0px 1px 1px 1px #0000001a;
}
.cusModalCloseL:hover{
    background: darkred;
}
.cusModalTitle{
    padding:5px;
    text-align:center;
    border-color: #bbb !important;
    border-bottom-color: #000 !important;
}
.cusModalBody{
    overflow: auto;
    height:calc(70vh - 50px);
    max-height:calc(70vh - 50px);
    padding:5px;
}
</style>